import { ChevronDown } from "react-feather";
import styles from "../communication-chat.module.css";
import { useEffect, useState } from "react";
import moment from "moment";

const TenantListunread = (props) => {
  const [tenantUnreadMessages, SetTenantUnreadMessages] = useState([]);
  const [unreadMessageCounter, setUnreadMessageCounter] = useState(0)

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
  };

  useEffect(() =>{
    
    const tenants =  props.unreadMessageList.filter((p) => p.type === 'Tenant' && p.message).sort((a, b) => {
      const timeA = a.message_time ? new Date(a.message_time).getTime() : 0;
      const timeB = b.message_time ? new Date(b.message_time).getTime() : 0;
      
      // Sort by message_time (latest first); those without time go to the bottom
      return timeB - timeA;
    });
    SetTenantUnreadMessages(tenants)
    const totalUnread = tenants.reduce((acc, message) => {
      return acc + message.unread_no;
    }, 0);
    setUnreadMessageCounter(totalUnread)
    

  },[props])

  return (
    <>
      
            <div
             
              onClick={() => props.toggleAccordionForTenantUnRead()}
              className={
                
                props.accordionOnForTenantUnRead
                  ? `${styles.accordionOn} ${styles.Open}`
                  : `${styles.accordionOn}`
              }
            >
              <p className={`${styles.ListAccoudionTitle}`}>
                <ChevronDown /> Tenants ({unreadMessageCounter} messages)
              </p>
              {tenantUnreadMessages.length > 0  &&
                tenantUnreadMessages.map((t) => {
                  return (
                    <div key={t.id}>
                      <div
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.CommuListSec}`
                            : `${styles.CommuListSec}`
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                      >
                        <div className={`${styles.CommuListDeSec}`}>
                          <p className={`${styles.ProsTenantList}`}>
                            <span>
                              {t?.first_name}&nbsp;{t?.last_name}
                            </span>
                            <img
                              src="/static/img/verified.svg"
                              className={`${styles.VarifiedBadgeTenant}`}
                              alt=""
                            />
                          </p>
                          {/* <p className={`${styles.ProsTenantListDe}`}>
                          <span>{t?.property_name}</span>
                            <span>{t?.apt_no}</span>
                            
                          </p> */}
                        </div>
                        {t?.message && <div className={`${styles.CommuListStatusSec}`}>
                          <p className={`${styles.UnreadMessageTime}`}>{moment(t?.message_time).fromNow()}</p>
                        </div>}
                        {t?.message && <div className={`${styles.UnreadMsgSec}`}>
                          <p className={t?.unread_no > 0 ? `${styles.UnreadMessage} ${styles.Unread}` : `${styles.UnreadMessage}`}>{t?.message}</p>
                        </div>}
                        { t?.unread_no > 0 && <div className={`${styles.UnreadMsgCountSec}`}>
                          <span className={`${styles.PropertyGroupNotiCount}`}>
                          {t?.unread_no}
                          </span>
                        </div>}
                        {!t?.message && <div className={`${styles.UnreadMsgSec}`}>
                          <p className={`${styles.CommounMsg}`}>--</p>
                        </div>}
                        {/* {t?.message && <div>
                          <div>{t?.message}</div>
                      <div>{moment(t?.message_time).fromNow()}</div>

                        </div>} */}
                      </div>

                      {/* <p
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.ProsTenantList}`
                            : `${styles.ProsTenantList}`
                        }
                      >
                        <span className={`${styles.Unit}`}>{t.apt_no}:</span>
                        <span className={`${styles.Name}`}>
                          {t?.first_name}&nbsp;{t?.last_name}
                        </span>{" "}
                        <span className={`${styles.Phone}`}>
                          {formatPhoneNumber(t.phone)}
                        </span>
                      </p> */}
                    </div>
                  );
                })}
              {tenantUnreadMessages.length === 0 && (
                <div className={`${styles.NoDataSecOnlyText}`}>
                  <img src="/static/img/chatIcon.png" alt=""/>
                  <p>No tenant here!</p>
                </div>
              )}
            </div>
          
       
    </>
  );
};

export default TenantListunread;
