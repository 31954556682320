import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import userInfoReducer from './userInfoReducer'
import prospectReducer from "./prospectCounterReducer";
import communicationChatBoxReducer from "./communicationChatBoxReducer";
export const rootReducer = combineReducers({
  themeReducer,
  userInfoReducer,
  prospectReducer,
  communicationChatBoxReducer
});
